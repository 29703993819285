@font-face {
  font-family: "Helevatica";
  src: url("../fonts/Helvetica.ttc");
}

select{
    width: 100%;
    word-wrap: normal;
    height: 50px;
    border-radius: 10px;
    background: transparent;
    margin-bottom: 20px;
    color: white!important;
}
select option{
    color: black;
}
.page-header{
    font-family: "Helevatica"!important;
    min-height: 100vh;
    background-image: url(../images/background.png) ;
    padding: 50px 50px 50px 270px;
    text-align: center;
    width: 100%;
}
.page-container{
    margin-top: 5%;
}
.logo{
    position: absolute;
    top: 35%;
    
}
.form{
    padding: 50px;
    border: 1px solid #CCCCC8;
    border-radius: 10px;
    margin: auto;
    background-color: transparent;
    color: white;
}

.label,
.form-label {
  display: block;
  text-align: left;
  color: #ccccc8 !important;
  font-weight: 600;
}

.item-control {
  text-align: left;
  background: transparent;
  fill: transparent;
}
.input {
  padding: 0px 20px;
  text-align: left;
  background: transparent;
  fill: transparent;
  margin: 15px 0px;
  width: 100%;
  height: 50px;
  color: #ccccc8;
  background: transparent;
  border: 1px solid #ccccc8;
  padding: 5px;
  border-radius: 10px;
  font-size: 18px;
}
.input:focus {
  border: none;
  border-radius: 10px;
}

.submit-btn {
  background: #ff4800 !important;
  text-align: center;
  border-radius: 50px;
  border: none;
  font-size: 24px;
  width: 100%;
  font-family: "Helevatica";
  font-weight: bold;
}
.submit-btn:hover {
  cursor: pointer;
  background-color: #b6b6b1;
}
.ant-form-item-explain {
  color: #b14d1d;
}

.ant-select-selection-placeholder {
  text-align: left;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  text-align: left;
}

@media (max-width: 1024px) {
  .page-container {
    left: 4%;
  }
}

@media (max-width: 768px) {
  .page-container {
    position: unset;
  }
  .logo {
    position: unset;
    margin-bottom: 50px;
  }
}
@media (max-width: 575px) {
  .page-header {
    background-size: cover;
    background-repeat: repeat;
    padding: 50px 15px;
  }
  .page-container {
    position: unset;
  }
  .logo {
    position: unset;
  }

  .mobile-logo {
    width: 100%;
  }

  .form {
    padding: 20px;
    border: none;
    margin: auto;
    background-color: transparent;
    width: 100%;
    color: white;
  }
}
