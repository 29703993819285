.viewSuper {
  margin-top: 145px !important;
  margin-right: 300px !important;
}
h1,
h2,
h3,
h4 {
  color: white !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fas {
  cursor: pointer !important;
}
.formModal{
  padding: 20px;
  border-radius: 10px;
  color: white;
}
.resend{
  display: block;
  background: transparent;
  border: none;
  color: red;
  font-size: 16px;
  margin-bottom: 20px;
}
.forget{
  color: red ;
  margin-bottom: 20px;
}
.logout-icon {
  font-size: 32px;
  color: white;
}
.loginContainer {
  margin: auto;
}
.ant-form-item-label label {
  color: white !important;
}
.loginBtn1 {
  background-color: #ffff4800 !important;
  background: #ffff4800 !important;
  border-radius: 10px !important;
  width: 70%;
  font-weight: bold !important;
  border: none !important;
  color: white !important;
  margin: auto !important;
}
.submit-btn {
  background: #ff4800 !important;
  margin: auto;
  width: 60%;
  text-align: center;
}

.submit-btn-add {
  float: right;
  margin: 40px 0px;
  width: 10%;
  font-weight: bold;
  background: #ff4800;
  padding: 5px 0px;
  font-size: 18px;
  border-radius: 20px;
  border: none;
  color: white;
}

.submit-btn-add:disabled{
  background: #d1663c;

}
.btn-add {
  float: right;
  margin: 40px 0px;
  font-weight: bold;
  background: #ff4800;
  color: white;
  width: 15%;
  padding: 5px 0px;
  font-size: 18px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.btn-card {
  font-weight: bold;
  background: #ff4800;
  color: white;
  padding: 5px 10px;
  letter-spacing: 2px;
  margin: 4px;
  font-size: 14px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.title {
  font-size: 20px;
}
.requestsBtn {
  font-weight: bold !important;
  background: #ff4800 !important;
  color: white !important;
  padding: 5px;
  font-size: 15px !important;
  border-radius: 20px !important;
  border: none !important;
  cursor: pointer;
}

.modal-btn {
  margin-bottom: 10px;
  font-weight: bold !important;
  background: #ff4800 !important;
  color: white !important;
  padding: 8px 15px;
  font-size: 16px !important;
  border-radius: 20px !important;
  border: none !important;
  cursor: pointer;
}
.btn-add:hover,
.btn-card:hover {
  background: #ad410f;
}
.submit-btn-add:hover {
  background: #ad410f;
}

.btn-delete {
  float: right;
  margin: 40px 0px;
  font-weight: bold;
  background: #bb100a;
  color: white;
  width: 15%;
  padding: 5px 0px;
  font-size: 18px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.announcement-card {
  background: #ff4800;
  cursor: pointer;
}
.btn-delete:hover {
  background: #970d08;
}
.login-fields-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: white;
  margin: auto;
}
.admin-name {
  color: white !important;
  font-size: 32px;
}

.header-logo {
  max-width: 260px;
}
.right-side-header {
  float: inline-end;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.label {
  font-weight: bold;
  margin: 10px 0px 10px;
  display: block !important;
  color: white;
}
.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 0px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  display: none;
  position: absolute;
  top: 120px;
  z-index: 10;
}
.pro-sidebar {
  position: absolute !important;
}

.branch-card p,
.branch-card h5,
.branch-card h6 {
  color: white;
}
.branch-card {
  text-align: center;
  width: 70%;
  margin: 20px auto;
  overflow-wrap: break-word;
  border: 1px solid white;
  min-height: 350px;
  padding: 20px;
}
.promocode{
  height: 280px;
}
.Card {
  text-align: center;
  margin: 20px auto;
  overflow-wrap: break-word;
  color: white;
  border: 3px solid white;
  border-radius: 10px;
  padding: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Card h5,
.Card p {
  color: white;
}
.Card h6 {
  color: white;

}
.Card a{
  width: 100%;
}
.articleTitle{
  margin-top: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .articleTitle:ho */
.session-card {
  text-align: center;
  width: 100%;
  margin: 20px auto;
  border: 3px solid white;
  padding: 20px;
}
.session-card p,
.session-card h5,
.session-card h6 {
  color: white;
}
.branch-details {
  border: 2px solid white;
  border-radius: 20px;
  padding: 40px 20px;
}
.branch-details li {
  color: white;
}
.branch-details ul {
  text-align: left;
}
.sessionContainer {
  margin-left: 3%;
  margin-right: 0px;
}

.client-image {
  width: 100%;
  border-radius: 50%;
}

.trainer-image {
  width: 145px;
  border-radius: 50%;
  height: 145px;
}
.announcement-image {
  width: 70%;
  height: 200px !important;
  cursor: pointer;
}
.trainer-details-image {
  width: 30%;
}

.ant-checkbox-wrapper span {
  color: white !important;
}
.card-home {
  color: white;
}

.pro-inner-item {
  border-bottom: 2px solid white;
}

.pro-item-content a:before {
  background: white !important;
  background-color: unset !important;
}
.navitem-active {
  background: rgb(51, 51, 51) !important;
}
.error {
  color: red;
}

.Toastify__toast--info {
  background: rgb(51, 51, 51) !important;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-light .navbar-nav .nav-link {
  color: white;
}
.dropdown-menu {
  left: unset !important;
  right: 0px !important;
}

.ant-input {
  color: #333333 !important;
  background-color: #f4f4f4 !important;
}
.freeze {
  text-align: center;
}
.freeze .freeze-span {
  color: white;
  margin-right: 10px;
}

.ant-switch-checked {
  background-color: #ff4800 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff4800 !important;
  border-color: #ff4800 !important;
}
.ant-alert{
  padding: 13px 15px !important;
}
.ant-alert-info {
  background-color: #404041 !important;
  border: 1px solid #404041 !important;
  border-bottom: 1px solid #242424 !important;
  color: white !important;
}
.ant-alert-message {
  color: white !important;
}
.selectedEvents {
  background-color: #ff4800 !important;
  color: black !important;
}
.ant-picker-calendar-date-value {
  padding-top: 9px !important;
}
.ant-picker-cell .ant-picker-cell-inner {
  height: 40px !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  background: #404041 !important ;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  color: white !important;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  background: #202020 !important;
}

.ant-picker-content thead tr th {
  color: white !important;
}

.ant-picker-calendar-header {
  background: #404041 !important;
}
.ant-picker-content th,
.ant-picker-content td {
  /* border:solid 1px white !important */
}
.requestsModel .modal-content {
  background-color: #1d1d1d !important;
}
.btn-label {
  background-color: indigo;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

.wrapper {
  /* height: 100%; */
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.file-upload {
  border-radius: 20px;
  font-weight: bold;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  overflow: hidden;
  background: #ff4800;
  background-size: 100% 200%;
  transition: all 1s;
  color: #ffffff;
  font-size: 18px;
}

.file-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-upload :hover {
  background-position: 0 -100%;
  color: #124e68;
}
.article-card .public-DraftStyleDefault-block span {
  color: white !important;
}
/* .public-DraftStyleDefault-block span{
     color: white!important;
   } */

.image-edit {
  width: 100%;
}
@media (max-width: 768px) {
  .btn-toggle {
    display: flex;
  }
}

.file-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-upload :hover {
  background-position: 0 -100%;
  color: #124e68;
}
@media (max-width: 768px) {
  .btn-toggle {
    display: flex;
  }
}

.tags {
  color: white;
  background-color: #ff4800;
  margin-right: 10px;
  padding: 8px;
  border-radius: 15px;
}

@media (max-width: 575px) {
  .btn-add {
    width: unset;
    padding: 10px;
  }
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: unset !important;
}
.ant-tabs-tab-btn {
  color: white !important;
  font-size: 20px;
}

.seperate {
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
  margin: 10px !important;
}

.ant-empty {
  color: white !important;
}

.field-error {
  border-color: red !important;
}

.upload {
  width: 100%;
  background: white;
}
.sessions .ant-picker-content th,
.sessions .ant-picker-content td {
  color: white;
}
.ant-picker-content th,
.ant-picker-content td:hover {
  color: black;
}
.ant-empty-description {
  color: white;
}
.searchInput {
  /* width: 70%; */
  padding: 5px;
}
.ant-empty {
  color: white !important;
}
.modal-btn {
  margin-bottom: 10px;
  font-weight: bold !important;
  background: #ff4800 !important;
  color: white !important;
  padding: 8px 15px;
  font-size: 16px !important;
  border-radius: 20px !important;
  border: none !important;
  cursor: pointer;
}
.clientsSelect {
  border-radius: unset;
  height: 36px;
}

.branchImgDiv {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #555;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
}
.overlay p {
  color: #ff590c;
  background: white;
  opacity: 1;
  padding: 10px;
  position: inherit;
}
.branchImgDiv:hover .overlay {
  opacity: 0.8;
  transition: opacity 0.5s ease-out;
}

.updateImg {
  width: 100%;
  height: 100%;
}
.updateImg::-webkit-file-upload-button {
  display: none;
}
.updateImg::before {
  content: "";
  display: inline-block;
  background: transparent;
  border: unset;
  cursor: pointer;
}

.updateImg:active::before {
  background: transparent;
}
input[type="file"] {
  color: rgba(0, 0, 0, 0);
}
.branchImg {
  width: 100% !important;
  height: 200px;
}

.ant-spin-dot{
  font-size: 100px !important;
  color: #ff590c !important;
}

.height{
  min-height: 315px;
}