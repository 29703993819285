/* @font-face {
    font-family: "SF Light";
    src: url("../fonts/FontsFree-Net-SFProDisplay-Light.ttf");
  }
  @font-face {
    font-family: "SF Medium";
    src: url("../fonts/FontsFree-Net-SFProDisplay-Medium.ttf");
  }
  @font-face {
    font-family: "SF Semibold";
    src: url("../fonts/FontsFree-Net-SFProDisplay-Semibold.ttf");
  } */
.bg-light {
    background-color: #EAEAEA!important;
    height: 60px;
}
.bg-dark{
    background: #1d1d1d!important;
}
.navbar-expand-lg .navbar-collapse{
    height: 100%;

}
.navbar-expand-lg .navbar-nav{
    height: 100%;
    padding-top: 10px;
}
.navbar{
    padding: 0px;
}

.Nav{
    background-color: white;
    border-radius: 60px 10px 0px 60px;
}


.Nav .nav-link{
    border-right: 1px solid #EAEAEA;
    padding-right: 20px!important;
    height: 40px;
}
.dropdown-toggle::after {
    margin-left: 0.255em;
    font-size: 32px;
}
.navbar-brand{
    padding: 10px;
    border-radius: 10px;
    z-index: 100000000;
    /* box-shadow: 0px 17px 32px #0000002B,0px 17px 32px #0000002B,0px 17px 32px #0000002B,0px 17px 32px #0000002B; */
}

.dropdown-toggle.nav-link{
    padding:0px 10px;
}

.dropdown-toggle.nav-link span{
    font-size: 25px;
    margin-left: 10px;
}

.far{
    font-size: 25px;
    margin-left: 25px;
}
